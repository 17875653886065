import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="animate-gradient"
export default class extends Controller {

  static values = {
    offset: Number
  };

  connect() {
    const elements = document.getElementsByClassName("gradient-percentage");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('offset', `${this.offsetValue}%`);
    }
    console.log(this.offsetValue);
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ['overlay'];

  connect() {
    console.log("Overlay Menu activated.");

    // Find the open and close buttons
    this.openButton = document.querySelector(".open-mobile-menu-btn");
    this.closeButton = document.querySelector(".close-mobile-menu-btn");
    
    if (this.openButton) {
      // Attach a click event listener to the open button
      this.openButton.addEventListener("click", () => {
        this.openOverlay();
      });
    } else {
      console.warn("Open button not found.");
    }

    if (this.closeButton) {
      // Attach a click event listener to the close button
      this.closeButton.addEventListener("click", () => {
        this.closeOverlay();
      });
    } else {
      console.warn("Close button not found.");
    }
  }

  openOverlay() {
    console.log("Open Mobile Menu Button clicked.");
    this.overlayTarget.classList.remove('hidden');
    document.body.classList.add("overlay-menu-open");
  }

  closeOverlay() {
    console.log("Close Mobile Menu Button clicked.");
    this.overlayTarget.classList.add('hidden');
    document.body.classList.remove("overlay-menu-open");
  }
}

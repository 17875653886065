import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  connect() {
    console.log("Give me the cookie consent, please? "+Cookies.get('allow_all_cookies'));
    const ConsentValue = Cookies.get('allow_all_cookies');
    const AllowAllCookiesButton = document.getElementById("allow_all_cookies_button");
    const AllowSelectedCookiesButton = document.getElementById("allow_selected_cookies_button");
    const AnalysisCheckBox = document.getElementById("analysis_and_statistics");
  
    if (ConsentValue === "true") {
      AnalysisCheckBox.checked = true;
    } else {
      AnalysisCheckBox.checked = false;
    }

    AllowAllCookiesButton.addEventListener('click', () => {
      var expiration_date = new Date();
      var cookie_string = '';
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      cookie_string = "allow_all_cookies=true; path=/; expires=" + expiration_date.toUTCString();
      document.cookie = cookie_string;

      console.log("All cookies allowed.");
      location.reload();
    })

    AllowSelectedCookiesButton.addEventListener('click', () => {
      var expiration_date = new Date();
      var cookie_string = '';
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      cookie_string = "allow_all_cookies=" + AnalysisCheckBox.checked + "; path=/; expires=" + expiration_date.toUTCString();
      document.cookie = cookie_string;

      console.log("All cookies allowed? "+AnalysisCheckBox.checked);
      location.reload();
    })

   
  }

  closeModal() {
    // Use Stimulus to trigger the Bootstrap modal's close method
    this.element.closest(".modal").classList.remove("show");
    this.element.closest(".modal").setAttribute("aria-hidden", true);
    document.body.classList.remove("modal-open");
    document.body.removeAttribute("style");
    const modalBackdrop = document.querySelector(".modal-backdrop");
    modalBackdrop.parentNode.removeChild(modalBackdrop);
  }
}

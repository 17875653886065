// app/javascript/controllers/contact_form_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("Contact form validation started.")


    const submitButton = document.getElementById("contact_form_submit_button")
    const contactForm = document.getElementById("contact-form")
    
    submitButton.addEventListener('click', this.checkValidity.bind(this))
    contactForm.addEventListener('change', this.checkValidity.bind(this))
  }

  checkValidity() {
    const prefix = document.getElementById("contact_form_message_prefix")
    const firstName = document.getElementById("contact_form_message_first_name")
    const lastName = document.getElementById("contact_form_message_last_name")
    const email = document.getElementById("contact_form_message_email")
    const content = document.getElementById("contact_form_message_content")
    const privacyPolicy = document.getElementById("contact_form_message_privacy_policy_agreed")

    this.validateInput(prefix)
    this.validateInput(firstName)
    this.validateInput(lastName)
    this.validateEmail(email)
    this.validateInput(content)
    this.validatePrivacyPolicy(privacyPolicy)
    
    contactForm.classList.add('was-validated')
  }

  validateInput(input) {
    console.log(!input.value)
    if (!input.value) {
      input.setCustomValidity(I18n.validation.blank_error)
      input.classList.add('is-invalid')
      input.classList.remove('is-valid')
    } else {
      input.setCustomValidity('')
      input.classList.add('is-valid')
      input.classList.remove('is-invalid')
    }
  }

  validateEmail(input) {
    console.log("Trying to validate E-Mail.")
    console.log(input.value)
    if (!input.value) {
      input.setCustomValidity(I18n.validation.blank_error)
      input.classList.add('is-invalid')
      input.classList.remove('is-valid')
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input.value)) {
      input.setCustomValidity(I18n.validation.email_error)
      input.classList.add('is-invalid')
      input.classList.remove('is-valid')
    } else {
      input.setCustomValidity('')
      input.classList.add('is-valid')
      input.classList.remove('is-invalid')
    }
  }

  validatePrivacyPolicy(input) {
    if (!input.checked) {
      input.setCustomValidity(I18n.validation.privacy_policy_error)
      input.classList.add('is-invalid')
      input.classList.remove('is-valid')
    } else {
      input.setCustomValidity('')
      input.classList.add('is-valid')
      input.classList.remove('is-invalid')
    }
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const htmlTag = document.querySelector("html");
    const colorMode = this.getCookie("colorModePreference");
    if (colorMode) {
      // If the user's color mode preference is already set in cookie, use that value
      htmlTag.setAttribute("data-bs-theme", colorMode);
      this.updateButton(colorMode);
    } else {
      // If the user's color mode preference is not set, check the system settings
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        // Dark mode is preferred by the user's system settings
        htmlTag.setAttribute("data-bs-theme", "dark");
        this.updateButton("dark");
        this.setCookie("colorModePreference", "dark");
      } else {
        // Light mode is preferred by the user's system settings
        htmlTag.setAttribute("data-bs-theme", "light");
        this.updateButton("light");
        this.setCookie("colorModePreference", "light");
      }
    }
  }

  toggle() {
    const htmlTag = document.querySelector("html");
    const currentColorMode = htmlTag.getAttribute("data-bs-theme");
    const newColorMode = (currentColorMode === "dark") ? "light" : "dark";

    htmlTag.setAttribute("data-bs-theme", newColorMode);
    this.updateButton(newColorMode);
    this.setCookie("colorModePreference", newColorMode);
  }

  updateButton(colorMode) {
    if (colorMode === "dark") {
      document.getElementById("dark-mode-button").classList.remove("d-none"); // Replace with your dark mode icon class
      document.getElementById("light-mode-button").classList.add("d-none");
    } else {
      document.getElementById("dark-mode-button").classList.add("d-none"); // Replace with your dark mode icon class
      document.getElementById("light-mode-button").classList.remove("d-none");
    }
  }

  setCookie(name, value) {
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)); // Cookie expires in 1 year
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookie(name) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name + "=") === 0) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
}
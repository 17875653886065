import "@hotwired/turbo-rails";

require("@rails/activestorage").start();
//require("trix")
//require("@rails/actiontext")
require("local-time").start();
require("@rails/ujs").start();

import "./channels/**/*_channel.js";
import "./controllers";

import * as bootstrap from "bootstrap";

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
});

